import router from './index'
import { store } from '@/store/index'

export const LINK_STATUS = {
  invalid: 0, //無効なリンク
  external: 1, //外部リンク
  internal: 2, //内部リンク
} as const

/**
 * urlが有効であるか検証する
 * @param {string} url - 画面遷移先url
 * @returns {number} - 外部リンクの場合1、内部リンクの場合2、それ以外0
 */
export const validateUrl = (url?: string): number => {
  if (! url) {
    return LINK_STATUS.invalid
  }

  const regexExternal = /^https?:\/\//
  const regexInternal = /^\/[/a-zA-Z0-9&?=_-]+$/
  if (regexExternal.test(url)) {
    // 外部リンク
    return LINK_STATUS.external
  } else if (regexInternal.test(url)) {
    // 内部リンク
    return LINK_STATUS.internal
  }

  return LINK_STATUS.invalid
}

/**
 * 外部リンクの場合は新規タブ、内部リンクの場合はvue-routerで画面遷移する
 * @param {string} url - 画面遷移先url
 * @param {boolean} isOpen - 新規タブフラグ
 */
export const externalOrInternalLink = (url?: string, isOpen = false): void => {
  if (! url) {
    return
  }

  const validationResult = validateUrl(url)
  if (validationResult === 1) {
    // 外部リンク
    window.open(url)
  } else if (validationResult === 2 && isOpen) {
    // 内部リンク（新規タブ）
    window.open(url)
  } else if (validationResult === 2) {
    // 内部リンク
    store.dispatch('menu/switchSidebarOpen')
    router.push(url)
  }
}
