export default {
  "word": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネーム"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
    "password_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワード"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード確認"])},
    "password_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定なし"])},
    "contents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件名"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本文"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更する"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
    "well_labo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウェルラボ"])}
  },
  "error": {
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインして下さい。"])},
    "413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードサイズが大きすぎます。"])},
    "429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエスト回数が多すぎます。しばらく時間を空けてからご利用ください。"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーでエラーが発生しました。しばらくしてからもう一度お試しください。"])}
  },
  "validation": {
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが一致しません。"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意してください。"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
    "save_login_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン状態を保存する。"])}
  },
  "logout": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウトしました。"])}
  },
  "forgot_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを忘れたときは？"])},
    "send_link_messeage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットするためのリンクを送信します。登録したメールアドレスを入力してください。"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再設定するためのメールを送信しました。届いたメールに記載のリンクをクリックして、パスワードの再設定画面を開いてください。"])}
  },
  "reset_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードリセット"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットしました。"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご入力ありがとうございました。お問合せを受け付けいたしました。"])}
  },
  "contact_faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["よくあるご質問"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どの講座についてのご質問ですか？"])}
  },
  "account": {
    "change_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前変更"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前を変更しました。"])}
    },
    "change_nickname": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネーム変更"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネームを変更しました。"])}
    },
    "change_email": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス変更"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを変更しました。"])},
      "send_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を完了するためのメールを送信しました。届いたメールに記載のリンクをクリックして、変更を完了してください。"])}
    },
    "change_password": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード変更"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを変更しました。"])}
    },
    "change_mode": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初期ページ変更"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初期ページを変更しました。"])}
    }
  },
  "terms": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご利用規約"])}
  },
  "notice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お知らせ"])}
  },
  "course": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受講中の講座"])}
  },
  "browsing_history": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての閲覧履歴"])}
  },
  "bookmark": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのブックマークした動画"])}
  },
  "display_mode": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初期ページ"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員サイト"])},
    "well_labo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウェルラボ"])}
  },
  "not_found": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページが見つかりませんでした。"])}
  },
  "no_video": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ただいま準備中です。動画のアップまでお待ちください。"])}
  },
  "seminar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セミナー日程"])},
    "button": {
      "before_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し込み開始前"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し込み終了しました"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し込み"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細確認"])}
    }
  },
  "seminar_details": {
    "title": {
      "seminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セミナー"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し込み"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細確認"])}
    },
    "apply": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加登録"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加登録する"])}
    }
  },
  "recommend": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたにおすすめの講座"])}
  },
  "counselingReports": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カウンセリングレポート"])}
  }
}