import { InjectionKey } from 'vue'
import { createStore, MutationTree, ActionTree, GetterTree, Store, useStore as baseUseStore } from 'vuex'
import { RootState } from '@/types/store/index'

import { base } from './base'
import { user } from './user'
import { menu } from './menu'
import { contact } from './contact'
import { notice } from './notice'
import { video } from './video'
import { seminar } from './seminar'
import { course } from './course'
import { counselingReport } from './counseling-reports'

const getters: GetterTree<RootState, RootState> = {}

const mutations: MutationTree<RootState> = {}

const actions: ActionTree<RootState, RootState> = {}

export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore({
  getters,
  mutations,
  actions,
  modules: {
    base,
    user,
    menu,
    contact,
    notice,
    video,
    seminar,
    course,
    counselingReport,
  }
})

export const useStore = (): Store<RootState> => {
  return baseUseStore(key)
}
