import { createRouter, createWebHistory, RouterOptions, RouterScrollBehavior } from 'vue-router'
import { routes } from './routes'

const scrollBehavior: RouterScrollBehavior = () => {
  return { top: 0 }
}

const routerOptions: RouterOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
}

const router = createRouter(routerOptions)

router.beforeEach(async (to, from, next) => {
  if (to.path.search('well_labo') >= 0){
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
