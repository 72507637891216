import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { BaseState, AlertState, ResponsiveState } from '@/types/store/base'

export const ALERT_COLORS = {
  danger: 'bg-red-100 border-red-400 text-red-700',
  info: 'bg-blue-100 border-blue-400 text-blue-700',
  warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
} as const

const initialValues: BaseState = {
  alert: {
    persistent: false,
    messages: undefined,
    color: ALERT_COLORS.danger
  },
  responsive: {
    isSM: false,
    isMD: false,
    isLG: false,
    isXL: false
  }
}

const state = (): BaseState => (initialValues)

const getters: GetterTree<BaseState, RootState> = {}

const mutations: MutationTree<BaseState> = {
  setAlertMessages (state, payload: AlertState) {
    state.alert.messages = payload.messages
    state.alert.persistent = payload.persistent != undefined ? payload.persistent : state.alert.persistent
    state.alert.color = payload.color ? payload.color : state.alert.color
  },
  setResponsive (state, payload: ResponsiveState) {
    state.responsive = payload
  }
}

const actions: ActionTree<BaseState, RootState> = {}

export const base: Module<BaseState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
