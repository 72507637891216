// css関連
import 'element-plus/dist/index.css'
import 'animate.css'
import './assets/tailwind.css'

// vue関連
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store, key } from './store'

// plugin関連
import i18n from './plugins/i18n'
import { setLocale } from 'yup'
import { yupLocale } from '@/locales/yup/ja'
setLocale(yupLocale)

// Component Library
import ElementPlus from 'element-plus' //なるべくTailwind CSSを使いたい
import ja from 'element-plus/es/locale/lang/ja'

createApp(App)
  .use(i18n)
  .use(store, key)
  .use(router)
  .use(ElementPlus, {
    locale: ja
  })
  .mount('#app')
