import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { VideoState } from '@/types/store/video'
import { VideoAttributes } from '@/types/models/video'
import { VideoBrowsingAttributes } from '@/types/models/video-browsing'
import { axios, ApiResponse } from '@/plugins/axios'
import { toArray } from '@/utils/array'

const initialValues: VideoState = {
  videos: undefined,
  videoDetails: undefined,
  videoBrowsings: undefined,
}

const state = (): VideoState => (initialValues)

const getters: GetterTree<VideoState, RootState> = {
  getLatestBrowsings: (state) => (): VideoBrowsingAttributes[] => {
    return toArray(state.videoBrowsings).slice(0, 4)
  },
}

const mutations: MutationTree<VideoState> = {
  setVideos (state, videos?: VideoAttributes[]) {
    state.videos = videos
  },
  setVideoDetails (state, videoDetails?: VideoAttributes) {
    state.videoDetails = videoDetails
  },
  setVideoBrowsings (state, videoBrowsings?: VideoBrowsingAttributes[]) {
    state.videoBrowsings = videoBrowsings
  }
}

const actions: ActionTree<VideoState, RootState> = {
  fetchVideos: async ({ commit }) => {
    const response = await axios.get<ApiResponse<VideoAttributes[]>>('/video')
    commit('setVideos', response.data.payload)
  },
  fetchVideoBrowsings: async ({ commit }) => {
    const response = await axios.get<ApiResponse<VideoBrowsingAttributes[]>>('/video/browsing')
    commit('setVideoBrowsings', response.data.payload)
  }
}

export const video: Module<VideoState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
