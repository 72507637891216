import axiosBase, { AxiosInstance, AxiosError } from 'axios'
import router from '@/router/index'
import i18n from './i18n'
import { store } from '@/store/index'
import { ALERT_COLORS } from '@/store/base'

export interface ApiResponse<T> {
  payload: T;
  errorMessages: string[];
}

const config = {
  withCredentials: true,
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
}

const axios: AxiosInstance = axiosBase.create(config)

axios.interceptors.response.use(
  response => response,
  (error: AxiosError<ApiResponse<undefined>>) => {
    if (error.response) {
      if (error.response.status === 403) {
        router.push('/not-found')
      }
      if (error.response.status === 422) {
        store.commit('base/setAlertMessages', { messages: error.response.data.errorMessages, color: ALERT_COLORS.danger })
      }
      if (error.response.status === 500) {
        store.commit('base/setAlertMessages', { messages: [i18n.global.t(`error.${error.response.status}`)], color: ALERT_COLORS.danger })
      }
    }
    return Promise.reject(error)
  }
)

export { axios }
