import { RouteRecordRaw } from 'vue-router'
import { authMiddleware, guestMiddleware } from './navigation-guards'

import Terms from '@/pages/Terms.vue'
import Login from '@/pages/Login.vue'
import Home from '@/pages/Home.vue'
import ForgotPassword from '@/pages/ForgotPassword.vue'
import FirstChangePassword from '@/pages/FirstChangePassword.vue'
import ResetPassword from '@/pages/ResetPassword.vue'
import Account from '@/pages/Account.vue'
import Contact from '@/pages/Contact.vue'
import AccountChangeEmail from '@/pages/AccountChangeEmail.vue'
import AccountConfirmEmail from '@/pages/AccountConfirmEmail.vue'
import AccountChangeName from '@/pages/AccountChangeName.vue'
import AccountChangeNickname from '@/pages/AccountChangeNickname.vue'
import AccountChangePassword from '@/pages/AccountChangePassword.vue'
import AccountChangeMode from '@/pages/AccountChangeMode.vue'
import NoticeList from '@/pages/NoticeList.vue'
import NoticeDetails from '@/pages/NoticeDetails.vue'
import PageDetails from '@/pages/PageDetails.vue'
import VideoList from '@/pages/VideoList.vue'
import VideoBrowsingHistory from '@/pages/VideoBrowsingHistory.vue'
import VideoBookmarkList from '@/pages/VideoBookmarkList.vue'
import VideoDetails from '@/pages/VideoDetails.vue'
import CourseList from '@/pages/CourseList.vue'
import SeminarList from '@/pages/SeminarList.vue'
import SeminarDetails from '@/pages/SeminarDetails.vue'
import RecommendCourseList from '@/pages/RecommendCourseList.vue'
import Calendar from '@/pages/Calendar.vue'
import QaCategoryList from '@/pages/QaCategoryList.vue'
import QaList from '@/pages/QaList.vue'
import QaDetails from '@/pages/QaDetails.vue'
import CounselingReportList from '@/pages/CounselingReportList.vue'
import CounselingReportDetails from '@/pages/CounselingReportDetails.vue'
import CounselingReportCreate from '@/pages/CounselingReportCreate.vue'
import CounselingReportEdit from '@/pages/CounselingReportEdit.vue'
import CounselingReportCopy from '@/pages/CounselingReportCopy.vue'
import NoVideo from '@/pages/NoVideo.vue'

import NotFound from '@/pages/NotFound.vue'

export const HOME_PATH = '/home'

export const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: HOME_PATH },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: guestMiddleware
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: guestMiddleware
  },
  {
    path: '/first/change-password/:token',
    name: 'FirstChangePassword',
    component: FirstChangePassword,
    beforeEnter: guestMiddleware
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: guestMiddleware
  },
  {
    path: HOME_PATH,
    name: 'Home',
    component: Home,
    beforeEnter: authMiddleware
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    beforeEnter: authMiddleware
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    beforeEnter: authMiddleware
  },
  {
    path: '/account/change-email',
    name: 'AccountChangeEmail',
    component: AccountChangeEmail,
    beforeEnter: authMiddleware
  },
  {
    path: '/account/confirm-email/:confirmation_token',
    name: 'AccountConfirmEmail',
    component: AccountConfirmEmail,
    beforeEnter: authMiddleware
  },
  {
    path: '/account/change-name',
    name: 'AccountChangeName',
    component: AccountChangeName,
    beforeEnter: authMiddleware
  },
  {
    path: '/account/change-nickname',
    name: 'AccountChangeNickname',
    component: AccountChangeNickname,
    beforeEnter: authMiddleware
  },
  {
    path: '/account/change-password',
    name: 'AccountChangePassword',
    component: AccountChangePassword,
    beforeEnter: authMiddleware
  },
  {
    path: '/account/change-mode',
    name: 'AccountChangeMode',
    component: AccountChangeMode,
    beforeEnter: authMiddleware
  },
  {
    path: '/notice',
    name: 'NoticeList',
    component: NoticeList,
    beforeEnter: authMiddleware
  },
  {
    path: '/notice/:notice_id',
    name: 'NoticeDetails',
    component: NoticeDetails,
    beforeEnter: authMiddleware
  },
  {
    path: '/page/:page_id',
    name: 'PageDetails',
    component: PageDetails,
    beforeEnter: authMiddleware
  },
  {
    path: '/video',
    name: 'VideoList',
    component: VideoList,
    beforeEnter: authMiddleware
  },
  {
    path: '/video/browsing-history',
    name: 'VideoBrowsingHistory',
    component: VideoBrowsingHistory,
    beforeEnter: authMiddleware
  },
  {
    path: '/video/bookmark',
    name: 'VideoBookmarkList',
    component: VideoBookmarkList,
    beforeEnter: authMiddleware
  },
  {
    path: '/video/:video_id',
    name: 'VideoDetails',
    component: VideoDetails,
    beforeEnter: authMiddleware
  },
  {
    path: '/course',
    name: 'CourseList',
    component: CourseList,
    beforeEnter: authMiddleware
  },
  {
    path: '/seminar',
    name: 'SeminarList',
    component: SeminarList,
    beforeEnter: authMiddleware
  },
  {
    path: '/seminar/:seminar_id',
    name: 'SeminarDetails',
    component: SeminarDetails,
    beforeEnter: authMiddleware
  },
  {
    path: '/recommend',
    name: 'RecommendCourseList',
    component: RecommendCourseList,
    beforeEnter: authMiddleware
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    beforeEnter: authMiddleware
  },
  {
    path: '/qa/category',
    name: 'QaCategoryList',
    component: QaCategoryList,
    beforeEnter: authMiddleware
  },
  {
    path: '/qa/category/:qa_sub_category_id',
    name: 'QaList',
    component: QaList,
    beforeEnter: authMiddleware
  },
  {
    path: '/qa/list/:qa_list_id',
    name: 'QaDetails',
    component: QaDetails,
    beforeEnter: authMiddleware
  },
  {
    path: '/counseling-reports',
    name: 'CounselingReportList',
    component: CounselingReportList,
    beforeEnter: authMiddleware
  },
  {
    path: '/counseling-reports/:counseling_report_id',
    name: 'CounselingReportDetails',
    component: CounselingReportDetails,
    beforeEnter: authMiddleware
  },
  {
    path: '/counseling-report-create/',
    name: 'CounselingReportCreate',
    component: CounselingReportCreate,
    beforeEnter: authMiddleware
  },
  {
    path: '/counseling-reports/edit/:counseling_report_id',
    name: 'CounselingReportEdit',
    component: CounselingReportEdit,
    beforeEnter: authMiddleware
  },
  {
    path: '/counseling-reports/copy/:counseling_report_id',
    name: 'CounselingReportCopy',
    component: CounselingReportCopy,
    beforeEnter: authMiddleware
  },
  {
    path: '/no-video',
    name: 'NoVideo',
    component: NoVideo,
    beforeEnter: authMiddleware
  },

  // *--- NotFound ---*
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
]
