import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { CourseState } from '@/types/store/course'
import { RecommendAttributes } from '@/types/models/recommend-course'
import { axios, ApiResponse } from '@/plugins/axios'

const initialValues: CourseState = {
  courses: undefined,
  recommendCourses: undefined,
}

const state = (): CourseState => (initialValues)

const getters: GetterTree<CourseState, RootState> = {
  getRecommendCourses: (state) => (): RecommendAttributes[] => {
    return state.recommendCourses
  },
}

const mutations: MutationTree<CourseState> = {
  setRecommendCourses (state, recommendCourses?: RecommendAttributes[]) {
    state.recommendCourses = recommendCourses
  },
}

const actions: ActionTree<CourseState, RootState> = {
  fetchRecommendCouses: async ({ commit }) => {
    const response = await axios.get<ApiResponse<RecommendAttributes[]>>('/course/recommend')
    commit('setRecommendCourses', response.data.payload)
  },
}

export const course: Module<CourseState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
