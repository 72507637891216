import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { MenuState } from '@/types/store/menu'
import { MenuCategoryAttributes } from '@/types/models/menu-category'
import { axios, ApiResponse } from '@/plugins/axios'

const initialValues: MenuState = {
  sidebar: {
    open: false
  },
  menuCategories: undefined,
}

const state = (): MenuState => (initialValues)

const getters: GetterTree<MenuState, RootState> = {}

const mutations: MutationTree<MenuState> = {
  setSidebarState (state, open: boolean) {
    state.sidebar.open = open
  },
  setMenuCategories (state, menuCategories?: MenuCategoryAttributes[]) {
    state.menuCategories = menuCategories
  }
}

const actions: ActionTree<MenuState, RootState> = {
  switchSidebarOpen ({ state, commit }) {
    commit('setSidebarState', !state.sidebar.open)
  },
  fetchMenuCategories: async ({ state, commit }) =>{
    if (! state.menuCategories) {
      const response = await axios.get<ApiResponse<MenuCategoryAttributes[]>>('/menu')
      commit('setMenuCategories', response.data.payload)
    }
  },
}

export const menu: Module<MenuState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
