import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { CounselingReportState } from '@/types/store/counseling-report'
import { CounselingReportAttributes } from '@/types/models/counseling-report'
import { CounselingSessionThemeAttributes } from '@/types/models/counseling-session-theme'
import { CounselingMentalNoiseAttributes } from '@/types/models/counseling-mental-noise'
import { CounselingClientChangedAttributes } from '@/types/models/counseling-client-changed'
import { axios, ApiResponse } from '@/plugins/axios'
import { toArray } from '@/utils/array'

const state = (): CounselingReportState => ({
  counselingReports: undefined,
  counselingReportDetails: undefined,
  counselingClientChangeds: undefined,
  counselingSessionThemes: undefined,
  counselingMentalNoises: undefined,
  searchDialogVisible: false
})

const getters: GetterTree<CounselingReportState, RootState> = {
  getLatestCounselingReports: (state) => (): CounselingReportAttributes[] => {
    return toArray(state.counselingReports).slice(0, 5)
  },
}

const mutations: MutationTree<CounselingReportState> = {
  setCounselingReports(state, counselingReports?: CounselingReportAttributes[]) {
    state.counselingReports = counselingReports
  },
  setCounselingClientChangeds(state, clientChangeds?: CounselingClientChangedAttributes[]) {
    state.counselingClientChangeds = clientChangeds
  },
  setCounselingSessionThemes(state, sessionThemes?: CounselingSessionThemeAttributes[]) {
    state.counselingSessionThemes = sessionThemes
  },
  setCounselingMentalNoises(state, mentalNoises?: CounselingMentalNoiseAttributes[]) {
    state.counselingMentalNoises = mentalNoises
  },
  setCounselingReportDetails (state, counselingReportDetails?: CounselingReportAttributes) {
    state.counselingReportDetails = counselingReportDetails
  },
}

const actions: ActionTree<CounselingReportState, RootState> = {
  fetchCounselingReports: async ({ state, commit }) => {
    if (!state.counselingReports) {
      const response = await axios.get<ApiResponse<CounselingReportAttributes[]>>('/counseling-reports')
      commit('setCounselingReports', response.data.payload)
    }
  },
  reloadCounselingReports: async ({ state, commit }) => {
    const response = await axios.get<ApiResponse<CounselingReportAttributes[]>>('/counseling-reports')
    commit('setCounselingReports', response.data.payload)
  },
  fetchCounselingClientChangeds: async ({ commit }) => {
    const response = await axios.get<ApiResponse<CounselingClientChangedAttributes[]>>('/counseling-reports/client-changeds')
    commit('setCounselingClientChangeds', response.data.payload)
  },
  fetchCounselingSessionThemes: async ({ commit }) => {
    const response = await axios.get<ApiResponse<CounselingSessionThemeAttributes[]>>('/counseling-reports/session-themes')
    commit('setCounselingSessionThemes', response.data.payload)
  },
  fetchCounselingMentalNoises: async ({ commit }) => {
    const response = await axios.get<ApiResponse<CounselingMentalNoiseAttributes[]>>('/counseling-reports/mental-noises')
    commit('setCounselingMentalNoises', response.data.payload)
  },
  searchCounselingReports: async ({ commit }, searchParams) => {
    commit('setCounselingReports', searchParams.data);
  },
}

export const counselingReport: Module<CounselingReportState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
