import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { NoticeState } from '@/types/store/notice'
import { NoticeAttributes } from '@/types/models/notice'
import { axios, ApiResponse } from '@/plugins/axios'
import { toArray } from '@/utils/array'

const state = (): NoticeState => ({
  notices: undefined,
})

const getters: GetterTree<NoticeState, RootState> = {
  getLatestNotices: (state) => (): NoticeAttributes[] => {
    return toArray(state.notices).slice(0, 5)
  },
}

const mutations: MutationTree<NoticeState> = {
  setNotices (state, notices?: NoticeAttributes[]) {
    state.notices = notices
  }
}

const actions: ActionTree<NoticeState, RootState> = {
  fetchNotices: async ({ state, commit }) =>{
    if (! state.notices) {
      const response = await axios.get<ApiResponse<NoticeAttributes[]>>('/notice')
      commit('setNotices', response.data.payload)
    }
  },
}

export const notice: Module<NoticeState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
