import { NavigationGuard } from 'vue-router'
import { axios, ApiResponse } from '@/plugins/axios'
import { store } from '@/store/index'
import i18n from '@/plugins/i18n'
import { ALERT_COLORS } from '@/store/base'
import { NextQueryItem, alertQueryKeys } from './queries'
import { UserAttributes } from '@/types/models/user'

export const authMiddleware: NavigationGuard = async (to, from, next) => {
  await axios.get<ApiResponse<UserAttributes>>('/account/user')
    .then(response => {
      store.commit('user/setUser', response.data.payload)
      next()
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // 元々遷移したかったpathをクエリで渡して、ログイン後に自動的に遷移させる
        const queryNext: NextQueryItem = {
          next: to.path,
        }
        store.commit('base/setAlertMessages', { messages: [i18n.global.t(`error.${error.response.status}`)], color: ALERT_COLORS.info, persistent: true })
        next({ name: 'Login', query: queryNext })
      } else {
        next()
      }
    })
}

export const guestMiddleware: NavigationGuard = (to, from, next) => {
  if (to.query[alertQueryKeys[0]] === '401') {
    store.commit('base/setAlertMessages', { messages: [i18n.global.t('error.401')], color: ALERT_COLORS.info, persistent: true })
  }

  axios.get('/sanctum/csrf-cookie')
    .catch((error) => {
      console.error('/sanctum/csrf-cookie', error)
    })
    .finally(() => {
      next()
    })
}
