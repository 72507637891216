import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { SeminarState } from '@/types/store/seminar'
import { SeminarAttributes } from '@/types/models/seminar'
import { axios, ApiResponse } from '@/plugins/axios'

const initialValues: SeminarState = {
  seminars: undefined,
  seminarDetails: undefined,
}

const state = (): SeminarState => (initialValues)

const getters: GetterTree<SeminarState, RootState> = {}

const mutations: MutationTree<SeminarState> = {
  setSeminars (state, seminars?: SeminarAttributes[]) {
    state.seminars = seminars
  },
  setSeminarDetails (state, seminarDetails?: SeminarAttributes) {
    state.seminarDetails = seminarDetails
  }
}

const actions: ActionTree<SeminarState, RootState> = {
  fetchSeminars: async ({ commit }) => {
    const response = await axios.get<ApiResponse<SeminarAttributes[]>>('/seminar')
    commit('setSeminars', response.data.payload)
  },
}

export const seminar: Module<SeminarState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
