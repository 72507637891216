import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { ContactState } from '@/types/store/contact'
import { axios } from '@/plugins/axios'

const initialValues: ContactState = {
  category: undefined,
  course: undefined,
  contact: {
    contact_category_id: undefined,
    contact_course_id: undefined,
    subject: undefined,
    body: undefined,
  },
  contactConfirm: {
    on: false
  },
  contactComplete: {
    on: false
  },
  faqCategories: undefined
}

const state = (): ContactState => (initialValues)

const getters: GetterTree<ContactState, RootState> = {
  getSubject: (state) => {
    return state.contact.subject
  },
  getBody: (state) => {
    return state.contact.body
  }
}

const mutations: MutationTree<ContactState> = {
  setCategory (state, category) {
    state.category = category
  },
  setCourse (state, course) {
    state.course = course
  },
  setcontact_category_id (state, contact_category_id) {
    state.contact.contact_category_id = contact_category_id
  },
  setcontact_course_id (state, contact_course_id) {
    state.contact.contact_course_id = contact_course_id
  },
  setSubject (state, subject) {
    state.contact.subject = subject
  },
  setBody (state, body) {
    state.contact.body = body
  },
  setContactConfirm (state, onOff) {
    state.contactConfirm.on = onOff
  },
  setContactComplete (state, onOff) {
    state.contactComplete.on = onOff
  },
  setFaqCategories (state, faqCategories) {
    state.faqCategories = faqCategories
  }
}

const actions: ActionTree<ContactState, RootState> = {
  fetchCategory: async ({ state, commit }) =>{
    if (! state.category) {
      const response = await axios.get('/contact/category')
      commit('setCategory', response.data.payload)
    }
  },
  fetchCourse: async ({ state, commit }) =>{
    if (! state.course) {
      const response = await axios.get('/contact/course')
      commit('setCourse', response.data.payload)
    }
  },
  contactConfirmOff ({ commit }) {
    commit('setContactConfirm', false)
  },
  contactConfirmOn ({ commit }) {
    commit('setContactConfirm', true)
  },
  contactCompleteOff ({ commit }) {
    commit('setContactComplete', false)
  },
  contactCompleteOn ({ commit }) {
    commit('setContactComplete', true)
  },
  fetchFaqCategories: async ({ state, commit }) =>{
    if (! state.faqCategories) {
      const response = await axios.get('/contact/faq')
      commit('setFaqCategories', response.data.payload)
    }
  },
}

export const contact: Module<ContactState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
